<template>
<!-- eslint-disable -->
		<v-menu
			v-model="menu"
			transition="scale-transition"
			offset-y
			min-width="auto"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-text-field
					v-model="date"
					label="Date"
					prepend-icon="mdi-calendar"
					readonly
					style="margin-left: 30px; border: none!important;"
					v-bind="attrs"
					v-on="on"
				></v-text-field>
			</template>
			<v-date-picker
				v-model="date"
				no-title
				scrollable
				width = "100%"
				@input="menu = false"
				@change="changeDate()"
			/>
		</v-menu>
</template>
<script>
export default {
    name: 'DatePicker',
	props: {
		date: {
			default: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
		},
	},
	data () {
		return {
			menu: false,
			// date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
		}
	},
	methods: {
		changeDate() {
			this.$emit('changeDate', this.date)
		},
	},
}
</script>
